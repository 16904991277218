import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import mixin from './mixin'
import globals from './globals'
import VueGtag from "vue-gtag";

Vue.config.productionTip = false

import '../public/css/Custom/result.css'
import '../public/css/Custom/slateblue.css'
import '../public/css/Custom/style.css'
import { BootstrapVue } from 'bootstrap-vue'
var VueScrollTo = require('vue-scrollto');
import Scrollspy from 'vue2-scrollspy';
import VueYoutube from 'vue-youtube'
import vueVimeoPlayer from 'vue-vimeo-player'
import VueMasonry from 'vue-masonry-css'
import i18n from './i18n'; 

Vue.use(VueScrollTo, {
  duration: 3000,
  easing: "ease"
})

Vue.use(BootstrapVue)
Vue.use(Scrollspy);
Vue.use(VueYoutube)
Vue.use(vueVimeoPlayer)
Vue.use(VueMasonry)
Vue.use(VueGtag, {
  config: { id: "G-YTVGYL9KBD" },
  enabled: process.env.VUE_APP_MODE === 'production',
  bootstrap: process.env.VUE_APP_MODE === 'production'
}, router)

Vue.mixin({
	data: globals,
  mixins: [mixin]
})

console.log('APP_VERSION: ' + process.env.VUE_APP_VERSION)
// console.log('process.env.VUE_APP_MODE: ' + process.env.VUE_APP_MODE)
// console.log('Gtag enabled: ')
// console.log(process.env.VUE_APP_MODE === 'production')
/*
console.log('process.env.VUE_APP_AWSU: ' + process.env.VUE_APP_AWSU)
console.log('process.env.VUE_APP_STK: ' + process.env.VUE_APP_STK)
console.log('process.env.VUE_APP_STK_PRICE: ' + process.env.VUE_APP_STK_PRICE)
*/
moment.locale('es')


const app = new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('app.rendered'));
  }
}).$mount('#app')

/*
document.addEventListener('DOMContentLoaded', function () {
  app.$mount('#app')
})
*/
