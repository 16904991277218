import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { AUTH_SIGNOUT } from '@/store/actions/auth'

const duppla = axios.create({
 baseURL: process.env.VUE_APP_AWSU,
 headers: {
		 "Accept": 'application/json',
		 "Content-Type": 'application/json'
	 }
 })

duppla.interceptors.response.use((response) => {
  return Promise.resolve(response)
}, (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    let httpStatus = error.response.status.toString()
    if (httpStatus.startsWith('4')) {
      console.debug('4xx error detected: ' + error.response.status)
      switch (error.response.status) {
        case 401: // Unauthorized (when token is not present)
          if (store.state.auth.isAuthenticated) {
            store.dispatch(AUTH_SIGNOUT).then(() => {
              router.push({ name: 'signout'})
            })
          }
          // error.response.data = { fieldErrors: [], globalErrors: [] }
          //return Promise.reject(error)
        case 403: // Forbidden (when token is present but expired)
          if (store.state.auth.isAuthenticated) {
            store.dispatch(AUTH_SIGNOUT).then(() => {
              router.push({ name: 'signout'})
            })
          }
          // this.toastWarn('El recurso solicitado se encuentra protegido y el usuario no tiene privilegios de acceso suficientes')
          //return Promise.reject(error)
        case 400: // Bad Request
          return Promise.reject(error)
        case 422: // Unprocessable Entity
          return Promise.reject(error)
        case 404: // Not Found
          // this.toastWarn('El recurso o información solicitada no ha sido encontrada o no existe')
          return Promise.reject(error)
        default:
          return Promise.reject(error)
      }
    } else if (httpStatus.startsWith('5')) {
      console.debug('5xx error detected: ' + error.response.status)
      // this.toastError('Ha ocurrido un error inesperado en el servidor, por favor intente más tarde, o bien, reporte a su administrador')
      // Adding custom validation errors to prevent errors in components
      // error.response.data = { fieldErrors: [], globalErrors: [] }
      return Promise.reject(error)
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    // this.toastError('Error local en la red: verifica que el dispositivo tiene una conexión activa a Internet')
    console.debug('Error local en la red: verifica que el dispositivo tiene una conexión activa a Internet')
    // Adding custom validation errors to prevent errors in components
    error.response = { data: { fieldErrors: [], globalErrors: [] } }
    return Promise.reject(error)
  } else {
    // Something happened in setting up the request that triggered an Error
    // this.toastError('Ha ocurrido un error inesperado en el cliente, por favor intente más tarde, o bien, reporte a su administrador')
    console.debug('Ha ocurrido un error inesperado en el cliente, por favor intente más tarde, o bien, reporte a su administrador')
    // Adding custom validation errors to prevent errors in components
    error.response = { data: { fieldErrors: [], globalErrors: [] } }
    return Promise.reject(error)
  }
})

export default duppla