export const formsEs = {
	form_init: {
			email_label: "Correo electrónico",
			email_placeholder: "Ingresa tu correo electrónico",
			age_label: "Edad",
			sex_label: "Sexo de nacimiento",
			female_option: "Mujer",
			male_option: "Hombre"
	},
	form_symptoms_step: {
			symptom_concern_label: "¿Padeces algún síntoma o malestar de preocupación?",
			yes_option: "Sí",
			no_option: "No",
			important_note: "IMPORTANTE: este cuestionario calcula el riesgo de personas sin síntomas ni preexistencia de cáncer.",
			specify_symptoms: "Por favor especifica cada síntoma que hayas presentado, así como el mes y año aproximado de aparición.",
			symptoms_example: "Ej. Vómitos, Náuseas, Mareos, Desmayos, Dolores, Inmovilidad, etc…",
			symptom_placeholder: "Síntoma/Malestar",
			add_symptom_button: "Agregar Síntoma",
			month_year_placeholder: "-- Mes y Año --",
			months_labels: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dec"]
	},
	form_ahf: {
			family_cancer_history_label: "¿Tienes antecedentes de cáncer en la familia?",
			specify_relatives_label: "Por favor especifica y agrega cuantos familiares sean necesarios:",
			cancer_detected_label: "Cáncer detectado",
			who_label: "¿Quién?",
			diagnosis_age_label: "¿Edad de Diagnóstico?",
			add_relative_button: "Agregar Familiar",
			yes_option: "Sí",
			no_option: "No",
			idk_option: "No lo sé",
			cancer_types: {
					colon: "Colon",
					endometrio: "Endometrio",
					mama: "Cáncer de Mama",
					prostata: "Próstata",
					pancreas: "Páncreas",
					ovario: "Ovario",
					other: "Otro/No recuerdo cual"
			},
			relatives: {
					grandparent: "Abuelo(a)",
					father: "Padre",
					mother: "Madre",
					uncle: "Tío(a)",
					brother: "Hermano",
					sister: "Hermana",
					cousin: "Primo(a)"
			},
			diagnosis_ages: {
					"0-39": "0-39",
					"40-44": "40-44",
					"45-49": "45-49",
					"50+": "50+"
			}
	},
	form_agine: {
			first_menstruation_age_label: "Edad de primera menstruación:",
			had_sex_label: "¿Has tenido relaciones sexuales?",
			vaginal_bleeding_label: "¿Has experimentado algún sangrado vaginal fuera de tu período menstrual habitual?",
			last_menstruation_age_label: "Edad de tu última o más reciente menstruación:",
			hormone_therapy_label: "¿Has utilizado terapias de sustitución hormonal por más de 5 años?",
			currently_pregnant_label: "¿Estás embarazada actualmente?",
			previous_pregnancies_label: "¿Has tenido embarazos previos?",
			number_of_pregnancies_label: "¿Cuántos embarazos previos has tenido?",
			number_of_pregnancies_placeholder: "Cuántos Embarazos",
			pregnancies_after_thirty_label: "¿Has tenido embarazos después de los 30?",
			lactation_months_label: "¿Cuántos meses has lactado en total?",
			lactation_months_placeholder: "Total Meses",
			lactation_example: "Ejemplo: primer bebé 4 meses, segundo bebé 2 meses, tu respuesta sería 6 meses.",
			breast_mass_label: "¿Has identificado recientemente alguna masa o bolita en tu mama que no estaba presente antes?",
			yes_option: "Sí",
			no_option: "No"
	},
	form_apnp: {
			smoke_label: "¿Fumas o has fumado?",
			smoke_years_label: "¿Cuántos años fumaste o has fumado?",
			smoke_qty_label: "¿Cuántos cigarros al día?",
			smoke_years_placeholder: "Cantidad de Años",
			smoke_qty_placeholder: "Cantidad de Cigarros",
			ex_smoker_label: "¿Eres exfumador?",
			years_since_quitting_label: "¿Hace cuántos años dejaste de fumar?",
			years_since_quitting_description: "Si es entre 1 y 11 meses indica 1 año.",
			years_since_quitting_placeholder: "Cantidad de Años",
			cough_extended_label: "¿Has presentado tos por más de 2 meses consecutivos?",
			cough_blood_label: "¿Has presentado tos con sangre?",
			alcohol_label: "¿Bebes alcohol?",
			alcohol_frequency_label: "¿Con qué frecuencia?",
			alcohol_amount_label: "¿Qué cantidad (copas) en esa frecuencia?",
			alcohol_amount_placeholder: "Cantidad de Copas",
			alcohol_years_label: "¿Cuántos años has bebido así?",
			alcohol_years_placeholder: "Cantidad de Años",
			exercise_label: "¿Haces ejercicio o tienes actividad física 3 o más días por semana?",
			exercise_time_label: "¿Haces más de 65 minutos de ejercicio intenso o 150 minutos de ejercicio moderado a la semana?",
			diet_label: "¿Qué describe mejor tu dieta típica?",
			diet_options: {
					normal: "Normal",
					vegetarian: "Vegetariana",
					hyposodic: "Hiposódica (Baja en Sodio)",
					hypofat: "Hipograsa (Baja en Grasa)",
					diabetic: "Para Diabéticos"
			},
			evacuation_changes_label: "¿Has experimentado cambios recurrentes y de forma prolongada en tus hábitos de evacuación?",
			yes_option: "Sí",
			no_option: "No",
			alcohol_frequency_options: {
					weekend: "Fin de semana",
					social: "Social",
					daily: "Diario"
			}
	},
	form_comorbidities: {
			weight_label: "Peso",
			weight_hint: "(en kilogramos sin decimales)",
			height_label: "Altura",
			height_hint: "(en centímetros sin decimales)",
			diabetes_label: "¿Tienes diabetes?",
			hyperlipidemia_label: "¿Tienes colesterol/triglicéridos altos (hiperlipidemia)?",
			yes_option: "Sí",
			no_option: "No"
	},
	form_end: {
			congratulations_message: "¡Felicidades!<br/>Has completado el test en línea de cáncer y ahora puedes tener un seguimiento personalizado en tu prevención.",
			opt_advice_label: "¿Estás de acuerdo en que mantengamos contacto directo contigo para lograrlo?",
			contact_confirmation_message: 'Al seleccionar "Sí" aceptas ser contactado por medio de WhatsApp.',
			contact_lada_label: "Lada de Contacto",
			contact_phone_label: "Teléfono de Contacto",
			contact_phone_placeholder: "10-digit phone number",
			terms_conditions_message: "Conoce tus resultados y recomendaciones personalizadas aceptando nuestros términos y condiciones así como la política de privacidad:",
			accept_terms_label: 'Acepto los <a href="/terminos/" target="_blank" class="text-primary">Términos y Condiciones</a>',
			accept_privacy_label: 'Acepto el <a href="/privacidad/" target="_blank" class="text-primary">Aviso de Privacidad</a>',
			captcha_alert: "Asegúrate de resolver el CAPTCHA para poder continuar",
			yes_option: "Sí",
			no_option: "No"
	},
	form_navigation: {
			previous_button: "Anterior",
			next_button: "Siguiente",
			view_results_button: "Ver resultados",
			processing_button: "Procesando...",
			error_message: "Ups... ha ocurrido un error inesperado, vuelve a intentar más tarde."
	}
};

export const formsEn = {
	form_init: {
			email_label: "Email Address",
			email_placeholder: "Enter your email",
			age_label: "Age",
			sex_label: "Sex Assigned at Birth",
			female_option: "Female",
			male_option: "Male"
	},
	form_symptoms_step: {
			symptom_concern_label: "Are you experiencing any symptoms or discomforts that have you worried?",
			yes_option: "Yes",
			no_option: "No",
			important_note: "IMPORTANT: This questionnaire estimates risk for people without symptoms or pre-existing cancer.",
			specify_symptoms: "Please specify each symptom you have experienced, including the approximate month and year of onset.",
			symptoms_example: "E.g. Vomiting, Nausea, Dizziness, Fainting, Pain, Immobility, etc.",
			symptom_placeholder: "Symptom/Discomfort",
			add_symptom_button: "Add Symptom",
			month_year_placeholder: "-- Month and Year --",
			months_labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
	},
	form_ahf: {
			family_cancer_history_label: "Do you have a family history of cancer?",
			specify_relatives_label: "Please specify and add as many relatives as needed:",
			cancer_detected_label: "Cancer detected",
			who_label: "Who?",
			diagnosis_age_label: "Age at Diagnosis?",
			add_relative_button: "Add Relative",
			yes_option: "Yes",
			no_option: "No",
			idk_option: "I don't know",
			cancer_types: {
					colon: "Colon",
					endometrio: "Endometrium",
					mama: "Breast Cancer",
					prostata: "Prostate",
					pancreas: "Pancreas",
					ovario: "Ovarian",
					other: "Other/Don't Remember"
			},
			relatives: {
					grandparent: "Grandparent",
					father: "Father",
					mother: "Mother",
					uncle: "Uncle/Aunt",
					brother: "Brother",
					sister: "Sister",
					cousin: "Cousin"
			},
			diagnosis_ages: {
					"0-39": "0-39",
					"40-44": "40-44",
					"45-49": "45-49",
					"50+": "50+"
			}
	},
	form_agine: {
			first_menstruation_age_label: "Age of first menstruation:",
			had_sex_label: "Have you been sexually active?",
			vaginal_bleeding_label: "Have you experienced any vaginal bleeding outside of your normal menstrual cycle in the last year?",
			last_menstruation_age_label: "Age of your last or most recent menstruation:",
			hormone_therapy_label: "Have you used hormone replacement therapy for more than 5 years?",
			currently_pregnant_label: "Are you currently pregnant?",
			previous_pregnancies_label: "Have you had previous pregnancies?",
			number_of_pregnancies_label: "How many previous pregnancies have you had?",
			number_of_pregnancies_placeholder: "Number of Pregnancies",
			pregnancies_after_thirty_label: "Have you had pregnancies after age 30?",
			lactation_months_label: "How many total months have you breastfed?",
			lactation_months_placeholder: "Total Months",
			lactation_example: "Example: first baby 4 months, second baby 2 months, your answer would be 6 months.",
			breast_mass_label: "Have you recently identified any new mass or lump in your breast that was not there before?",
			yes_option: "Yes",
			no_option: "No"
	},
	form_apnp: {
			smoke_label: "Do you smoke or have you smoked?",
			smoke_years_label: "How many years have you smoked or did you smoke?",
			smoke_qty_label: "How many cigarettes per day?",
			smoke_years_placeholder: "Number of Years",
			smoke_qty_placeholder: "Number of Cigarettes",
			ex_smoker_label: "Are you a former smoker?",
			years_since_quitting_label: "How many years ago did you quit smoking?",
			years_since_quitting_description: "If it's between 1 and 11 months, indicate 1 year.",
			years_since_quitting_placeholder: "Number of Years",
			cough_extended_label: "Have you experienced a cough lasting more than 2 consecutive months?",
			cough_blood_label: "Have you coughed up blood?",
			alcohol_label: "Do you drink alcohol?",
			alcohol_frequency_label: "How often?",
			alcohol_amount_label: "What quantity (drinks) in that frequency?",
			alcohol_amount_placeholder: "Number of Drinks",
			alcohol_years_label: "For how many years have you been drinking this way?",
			alcohol_years_placeholder: "Number of Years",
			exercise_label: "Do you exercise or have physical activity 3 or more days per week?",
			exercise_time_label: "Do you get more than 65 minutes of vigorous exercise or 150 minutes of moderate exercise per week?",
			diet_label: "Which best describes your typical diet?",
			diet_options: {
					normal: "Normal",
					vegetarian: "Vegetarian",
					hyposodic: "Low Sodium",
					hypofat: "Low Fat",
					diabetic: "Diabetic"
			},
			evacuation_changes_label: "Have you noticed ongoing changes in your bowel habits, like diarrhea, constipation, or unusual stool consistency?",
			yes_option: "Yes",
			no_option: "No",
			alcohol_frequency_options: {
					weekend: "Weekend",
					social: "Social",
					daily: "Daily"
			}
	},
	form_comorbidities: {
			weight_label: "Weight",
			weight_hint: "(in kilograms, no decimals)",
			height_label: "Height",
			height_hint: "(in centimeters, no decimals)",
			diabetes_label: "Do you have diabetes?",
			hyperlipidemia_label: "Do you have high cholesterol/triglycerides (hyperlipidemia)?",
			yes_option: "Yes",
			no_option: "No"
	},
	form_end: {
			congratulations_message: "Congrats! <br/> You've completed the online cancer test. Now, get ready for personalized follow-up to help you stay on top of your prevention.",
			opt_advice_label: "Do you agree to allow us to maintain direct contact with you to achieve this?",
			contact_confirmation_message: 'By selecting "Yes" you agree to be contacted via WhatsApp.',
			contact_lada_label: "Contact Area Code",
			contact_phone_label: "Contact Phone Number",
			contact_phone_placeholder_en: "Phone Number",
			terms_conditions_message: "Learn your results and personalized recommendations by accepting our terms and conditions as well as the privacy policy:",
			accept_terms_label: 'I accept the <a href="/terminos/" target="_blank" class="text-primary">Terms and Conditions</a>',
			accept_privacy_label: 'I accept the <a href="/privacidad/" target="_blank" class="text-primary">Privacy Policy</a>',
			captcha_alert: "Make sure to solve the CAPTCHA to continue",
			yes_option: "Yes",
			no_option: "No"
	},
	form_navigation: {
			previous_button: "Previous",
			next_button: "Next",
			view_results_button: "View Results",
			processing_button: "Processing...",
			error_message: "Oops... an unexpected error has occurred, please try again later."
	}
};
