import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'questionnaire',
    component: () => import('../views/public/questionnaire')
  },
  {
    path: '/private/resultado',
    name: 'questionnaire-result',
    component: () => import('../views/private/questionnaire-result'),
    props: true
  },
  {
    path: '/terminos',
    name: 'terms-of-service',
    component: () => import('../views/public/terms-of-service')
  },
  {
    path: '/privacidad',
    name: 'privacy',
    component: () => import('../views/public/privacy')
  },
  {
    path: '*',
    name: 'indirect 404',
    component: () => import('../views/public/404')
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})
/*
router.beforeEach((to, from, next) => {
  // Set loading state
  //document.body.classList.add('app-loading')
  // console.debug('Router from ' + from.path + ' to ' + to.path)
  let accessToken = store.state.auth.accessToken
  let isAuthenticated = store.state.auth.isAuthenticated

  if (to.path.indexOf('/private') >= 0) {
    if (!isAuthenticated && !accessToken) {
      // console.debug('[index.js router] not authenticated, redirecting to signout')
      next('/')
      return
    }
  }

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)
})
*/
export default router
