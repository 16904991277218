import { AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, AUTH_SIGNOUT, AUTH_EXPIRY_ALERT } from '../actions/auth'
import duppla from '../../duppla'
import router from '../../router'
import jwt_decode from "jwt-decode"
import moment from 'moment'

const state = { 
	status: '', 
	isAuthenticated: false,
	accessToken: localStorage.getItem('access-token') || '', 
	tokenPayload: null, 
	tokenExpirarionAlert: false 
}

const actions = {
  [AUTH_REQUEST]: ({commit, dispatch}, payload) => {
  	console.debug('AUTH_REQUEST dispatch')
    return new Promise((resolve, reject) => {
      
      commit(AUTH_REQUEST)
      console.debug('AUTH_REQUEST commited')

      console.debug('signin')
      duppla({ url: 'signin', method: 'POST', data: payload })
        .then(response => {
        	console.debug('Signed In: ')
          const accessToken = response.data.token// resp.data.token
          console.debug(accessToken)
          dispatch(AUTH_SUCCESS, accessToken)
          console.debug('AUTH_REQUEST resolved')
          resolve(accessToken)
        })
        .catch(error => {
        	console.debug('Error signing in: ')
        	console.error(error)
        	localStorage.removeItem('access-token')
          delete duppla.defaults.headers.common['Authorization']
          commit(AUTH_ERROR, error)
          reject(error)
        })
    })
  },
  [AUTH_SUCCESS]: ({commit, dispatch}, accessToken) => {
  	console.debug('AUTH_SUCCESS dispatched')
    localStorage.setItem('access-token', accessToken) // store the token in localstorage
    // const userId = jwtDecode(token).pid
    duppla.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
    var decoded = jwt_decode(accessToken)
    console.debug(decoded)
    var expiryDate = decoded.exp ? moment(moment.unix(decoded.exp)) : null
    // console.debug('expiryDate: ' + expiryDate)
    let logoutTimeout = null
    let expiryAlertTimeout = null

		console.debug('AUTH_SUCCESS commited')
    commit(AUTH_SUCCESS, {accessToken: accessToken, payloadToken: decoded, logoutTimeout: logoutTimeout, expiryAlertTimeout: expiryAlertTimeout})

    if (expiryDate) {
      let milisNotify = 30000 // 5 minutes
      let now = moment()
      let milisToexpire = expiryDate.diff(now)

      console.debug("milisToexpire: " + milisToexpire)

      if (milisToexpire <= 0) {
      	dispatch(AUTH_SIGNOUT).then(() => { 
          console.debug('router.currentRoute.path: ' + router.currentRoute.path)
          console.debug('window.location.pathname: ' + window.location.pathname)
          console.debug('signed out')
      		if(router.currentRoute.path.indexOf('/private') >= 0 || window.location.pathname.indexOf('/private') >= 0) {
      			console.debug('In a private directory, redirecting to signout')
        		router.push('/signout') 
        	}
      	})
      } else {
        
	      logoutTimeout = setTimeout(() => {
	        if (state.isAuthenticated) {
	          dispatch(AUTH_SIGNOUT).then(() => {
	          	console.debug('signed out')
	          	if(router.currentRoute.path.indexOf('/private') >= 0) {
	          		console.debug('In a private directory, redirecting to signout')
	          		router.push('/signout') 
	          	}
	          })
	        }
	      }, milisToexpire)
	      expiryAlertTimeout = setTimeout(() => {
	        commit(AUTH_EXPIRY_ALERT)
	        // console.debug('Warning, token is about to expire')
	      }, milisToexpire - milisNotify)
        
	    }
    }
  },
  [AUTH_SIGNOUT]: ({commit, dispatch}) => {
  	console.debug('AUTH_SIGNOUT dispatch')
    return new Promise((resolve, reject) => {
      if (state.logoutTimeout) clearTimeout(state.logoutTimeout)
      if (state.expiryAlertTimeout) clearTimeout(state.expiryAlertTimeout)
      commit(AUTH_SIGNOUT)
    	console.debug('AUTH_SIGNOUT commited')
    	localStorage.removeItem('access-token')
      delete duppla.defaults.headers.common['Authorization']
      localStorage.setItem('duppla_signout', Date.now())
      console.debug('AUTH_SIGNOUT resolved')
      resolve()
    })
  }
}

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status ='loading' 
		state.isAuthenticated = false
		state.accessToken = ''
		state.tokenPayload = {}
		state.tokenExpirarionAlert = false
		state.logoutTimeout = null
    state.expiryAlertTimeout = null
  },
  [AUTH_SUCCESS]: (state, data) => {
    state.status = 'authenticated'
		state.isAuthenticated = true
		state.accessToken = data.accessToken
		state.tokenPayload = data.payloadToken
		state.tokenExpirarionAlert = false
		state.logoutTimeout = data.logoutTimeout
    state.expiryAlertTimeout = data.expiryAlertTimeout
  },
  [AUTH_ERROR]: (state) => {
    state.status = 'error'
		state.isAuthenticated = false
		state.accessToken = ''
		state.tokenPayload = {}
		state.tokenExpirarionAlert = false
		state.logoutTimeout = null
    state.expiryAlertTimeout = null
  },
  [AUTH_SIGNOUT]: (state) => {
    state.status = 'signout'
		state.isAuthenticated = false
		state.accessToken = ''
		state.tokenPayload = {}
		state.tokenExpirarionAlert = false
		state.logoutTimeout = null
    state.expiryAlertTimeout = null
  },
  [AUTH_EXPIRY_ALERT]: (state) => {
    state.tokenExpirarionAlert = true
  }
}

export default {
  state,
  actions,
  mutations
}
