import { months } from "moment/moment";
import Vue from "vue";
import VueI18n from "vue-i18n";
import { formsEn, formsEs } from "./texts/forms";
import { resultEn, resultEs } from "./texts/result";
Vue.use(VueI18n);

// Definición de los mensajes para diferentes idiomas
const messages = {
	es: {
		welcome: "Bienvenido",
		yes: "Sí",
		morning_greeting: "¡Buenos días!",
		afternoon_greeting: "¡Buenas tardes!",
		evening_greeting: "¡Buenas noches!",
		init_text:
			"Arranquemos tu prevención del cáncer.<br/><br/> Comparte tus datos para enviarte la información.",
		symptoms_step_text:
			"Un cáncer es una célula que crece de forma descontrolada.<br/> ¿Qué detona este crecimiento? <br/> Hay diferentes factores que exploraremos en este cuestionario, pero primero, compártenos.",
		ahf_text:
			"El primer tipo de factor de riesgo es hereditario. Conocer si hay casos de cáncer en nuestra familia, puede indicar si este factor nos impacta.",
		agine_text:
			"Existen factores de riesgo hormonales, así como de exposición a virus transmitidos por actividad sexual. Estas preguntas nos ayudarán a conocer su impacto en tu perfil de riesgo.",
		apnp_text:
			"Las decisiones de estilo de vida también impactan tu factor de riesgo de desarrollo de cáncer.",
		comorbidities_text:
			"Un factor adicional es la presencia de otras enfermedades, como diabetes, hiperlipidemia u obesidad.",
		validation: {
			validating_access: "Estamos validando tu liga de acceso...",
			start_questionnaire_title:
				"¿Quieres saber cómo prevenir el cáncer en menos de 5 minutos?",
			start_questionnaire_description:
				'Responde este cuestionario en <span class="text-primary font-weight-bold">60 segundos</span> y obtén un plan personalizado para reducir tu riesgo.<br />',
			free_thanks_to: "SIN COSTO PARA TI gracias a:",
			start_here_button: "¡Inicia aquí!",
			no_one_dies_of_cancer:
				"Queremos vivir en un país donde nadie muere por cáncer",
			duppla_tool_description:
				'En <span class="text-primary font-weight-bold">Duppla</span>, hemos creado esta herramienta con un grupo de oncólogos y bioestadistas de reconocimiento global. En menos de 5 minutos una persona obtiene recomendaciones de prevención personalizadas a partir de su perfil de riesgo individual.',
			service_invitation_only:
				"Este servicio está disponible mediante invitación únicamente.",
			interested_in_checkup:
				"¿Te interesaría hacerte el Checkup Digital de cáncer?",
		},
		progress: {
			progress_title: "Progreso",
			questionnaire_progress: " del cuestionario",
			step: "Paso",
			of: "de",
		},
		loading: {
			loading_medical_record: "Cargando...",
		},
		...formsEs,
		...resultEs,
	},
	en: {
		welcome: "Welcome",
		yes: "Yes",
		morning_greeting: "Good morning!",
		afternoon_greeting: "Good afternoon!",
		evening_greeting: "Good evening!",
		init_text:
			"Ready to start your cancer prevention journey? <br/><br/> Share your info, and we’ll send you personalized insights right away.",
		symptoms_step_text:
			"Cancer begins with a single cell growing out of control. <br/> What causes this? <br/> Uncover the factors in this quick questionnaire—just share your info to get started.",
		ahf_text:
			"The first risk factor is heredity. Family history of cancer can reveal if this risk affects you. Let’s find out together.",
		agine_text:
			"There are hormonal risk factors, as well as exposure to sexually transmitted viruses. These questions will help us understand their impact on your risk profile.",
		apnp_text:
			"Lifestyle choices also impact your risk factor for developing cancer.",
		comorbidities_text:
			"An additional factor is the presence of other diseases, such as diabetes, hyperlipidemia or obesity.",
		validation: {
			validating_access: "We are validating your access link...",
			start_questionnaire_title:
				"Want to discover how you can start preventing cancer in just 5 minutes?",
			start_questionnaire_description:
				"Take 60 seconds to answer this questionnaire and unlock your personalized plan to lower your cancer risk.",
			free_thanks_to: "Completely free for you, made possible by:",
			start_here_button: "Start here!",
			no_one_dies_of_cancer:
				"We want to live in a country where no one dies of cancer",
			duppla_tool_description:
				'At <span class="text-primary font-weight-bold">Duppla</span>, we have created this tool with a group of globally recognized oncologists and biostatisticians. In less than 5 minutes, a person gets personalized prevention recommendations based on their individual risk profile.',
			service_invitation_only: "This service is available by invitation only.",
			interested_in_checkup:
				"Would you be interested in the Digital Cancer Checkup?",
		},
		progress: {
			progress_title: "Progress",
			questionnaire_progress: " of the questionnaire",
			step: "Step",
			of: "of",
		},
		loading: {
			loading_medical_record: "Loading...",
		},
		...formsEn,
		...resultEn,
	},
};

console.log("🚀 ~ messages:", messages);

// Configuración de vue-i18n
const i18n = new VueI18n({
	locale: "es", // Idioma predeterminado
	fallbackLocale: "en", // Idioma de respaldo
	messages,
});

export default i18n;
